@import 'src/variables';

.info-items {
  display: flex;
  flex-direction: column;
  .row {
    display: flex;
    flex-direction: column;
    h2 {
      margin: 0;
      color: $gray800;
      margin-bottom: 24px;
      margin-left: 24px;
    }
    .content {
      display: flex;
      flex: 1;
      .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        padding: 0 40px;
        .wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .label {
          color: $gray400;
          font-size: 14px;
          margin-bottom: 8px;
          margin-left: 4px;
          display: block;
        }
        .content {
          color: $gray800;
          background-color: $gray100;
          padding: 10px;
          border-radius: $borderRadius;
        }
        & + .item {
          border-left: $border;
        }
      }
    }
    & + .row {
      margin-top: 24px;
      padding-top: 24px;
      border-top: $border;
    }
  }
}
