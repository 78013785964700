@import 'src/variables';

.table-list-wrapper {
  border-radius: 4px;
  border-radius: $borderRadius;
  background: $white;
  position: relative;
  .table {
    width: 100%;
    border-spacing: 0;
    thead {
      tr {
        background-color: $gray100;
        th {
          span {
            display: flex;
            align-items: center;
          }
          .sort-icon {
            margin: auto 0;
            margin-left: 4px;
            transition: 0.15s;
            border-radius: 50%;
            padding: 4px;
            width: 18px;
            height: 18px;
            * {
              transition: 0.15s;
            }
          }
          &[data-sortable] {
            cursor: pointer;
            transition: 0.15s;
            user-select: none;
            &:hover {
              .sort-icon {
                background-color: $main400;
                path {
                  stroke: #fff;
                }
              }
            }
          }
          &[data-sorting='desc'] {
            .sort-icon {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    td,
    th {
      padding: 20px 10px;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.13px;
      color: $gray800;
      font-weight: 400;
      text-align: start;
      border-bottom: 1px solid $gray125;
      &.bold {
        font-weight: 500;
      }
      &:last-child {
        padding-right: 20px;
      }
      &:first-child {
        padding-left: 20px;
      }
      span {
        color: $gray400;
      }
    }
    tbody {
      tr {
        &:nth-child(even) {
          background: $gray50;
        }
        &.link {
          transition: background-color 0.1s;
          td {
            padding: 0;
            a {
              padding: 20px 10px;
              width: 100%;
              height: 100%;
              display: inline-block;
            }
            &:last-child {
              padding-right: 20px;
            }
            &:first-child {
              padding-left: 20px;
            }
          }
          &:hover {
            background-color: $main100;
            td,
            a:hover {
              color: $gray800;
            }
          }
        }
      }
    }
  }
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 24px 16px;
    .pagination {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      align-items: center;
      li {
        a {
          transition: 0.15s;
          border-radius: 50%;
          cursor: pointer;
          display: block;
        }
      }
      li:not(.previous):not(.next) a {
        width: 34px;
        height: 34px;
        font-size: 16px;
        line-height: 34px;
        text-align: center;
        letter-spacing: -0.18px;
        color: $gray400;
        &:hover,
        &.active {
          background: $main100;
          color: $main400;
        }
      }
      li + li {
        margin-left: 16px;
      }
      li.previous,
      li.next {
        a {
          position: relative;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            transform: rotate(90deg);
            * {
              stroke: $main400;
              transition: 0.15s;
            }
          }
          &:hover {
            background: $main100;
            svg * {
              stroke: $main400;
            }
          }
        }
        &.disabled {
          pointer-events: none;
          cursor: pointer;
          svg * {
            stroke: $gray300;
          }
        }
      }
      li.next {
        transform: scaleX(-1);
      }
    }
    .per-page {
      display: flex;
      align-items: center;
      span {
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.04px;
        color: $gray400;
        margin-right: 4px;
      }
      ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          height: 32px;
          padding: 4px;
          min-width: 40px;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          letter-spacing: -0.18px;
          color: $gray400;
          border: 1px solid $gray200;
          transition: 0.15s;
          cursor: pointer;
          &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
          &.active {
            border-color: $main400;
            color: $main400;
            font-weight: 600;
            font-size: 11px;
          }
        }
      }
    }
  }
  .fetching {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    overflow: hidden;
    border-radius: 2px;
    &:before {
      position: absolute;
      content: '';
      width: 30%;
      height: 100%;
      background: $main400;
      animation: fetching 1s infinite linear;
    }
  }
}

@keyframes fetching {
  0% {
    left: 0;
    width: 0;
  }
  25% {
    left: 0;
    width: 50%;
  }
  75% {
    width: 70%;
    left: 30%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}
