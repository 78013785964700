@import 'src/variables';

.auth-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border: 2px solid $main400;
  border-radius: 100px;
  height: 48px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: color 0.15s;
  span {
    margin-left: 16px;
  }
  svg {
    width: 26px;
  }
  span,
  svg {
    z-index: 1;
  }
  &::before {
    position: absolute;
    content: '';
    background: $main400;
    width: 0;
    height: 0;
    transition: 0.15s ease-in;
    border-radius: 50%;
  }
  &:hover {
    color: $white;
    &::before {
      width: 600px;
      height: 600px;
    }
  }
}
