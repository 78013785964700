@import 'src/variables';

.select {
  position: relative;
  .control {
    position: relative;
    padding: 13.5px 40px 13.5px 20px;
    border-radius: $borderRadius;
    border: $border;
    transition: 0.15s;
    cursor: pointer;
    user-select: none;
    span {
      height: 19px;
      display: inline-block;
      color: $gray300;
      transition: 0.15s;
      &.filled {
        color: $gray800;
      }
    }
    .arrow {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      border-color: $gray300;
    }
  }
  .dropdown {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    border-radius: $borderRadius;
    border: $border;
    z-index: 100;
    background-color: $white;
    overflow: hidden;
    .option {
      padding: 8px 12px;
      cursor: pointer;
      transition: 0.1s;
      color: $gray400;
      &:hover {
        background-color: $main400;
        color: $white;
      }
    }
  }
  &.valid {
    .control {
      border-color: $main400;
    }
  }
  &.invalid {
    .control {
      border-color: $red400;
    }
  }
}

@media screen and (max-width: 576px) {
  .select {
    .control,
    .dropdown .option {
      font-size: 14px;
      line-height: 17px;
    }
  }
}
