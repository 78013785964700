@import 'src/variables';

.labeled-field {
  display: flex;
  flex-direction: column;
  position: relative;
  .label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    position: absolute;
    background: $white;
    padding: 0 10px;
    left: 11px;
    top: -8px;
    z-index: 1;
  }
  & + .labeled-field {
    margin-top: 24px;
  }
  &.invalid {
    color: $red400;
  }
  &.valid {
    color: $main400;
  }
}
