@import 'src/variables';

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  .view {
    width: 16px;
    height: 16px;
    border: 2px solid $gray200;
    border-radius: 2px;
    transition: 0.15s;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 11px;
  }
  &[data-checked='true'] {
    .view {
      background: $main400;
      border-color: $main400;
    }
  }
}

@media screen and (max-width: 576px) {
  .checkbox span {
    font-size: 14px;
    line-height: 17px;
  }
}
