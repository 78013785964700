@import 'src/variables';

.auth-actions {
  display: flex;
  justify-content: space-between;
}

.separator {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: $gray300;
  position: relative;
  text-align: center;
  margin: 40px 0;
  span {
    background: $white;
    padding: 0 24px;
    position: relative;
  }
  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    left: 0;
    top: 50%;
    background: $gray200;
    opacity: 0.6;
  }
}

.sign-up {
  text-align: center;
  margin-top: 60px;
  a {
    color: $main400;
    text-decoration: underline;
    padding: 2px;
    position: relative;
    transition: 0.18s;
    &::before {
      position: absolute;
      content: '';
      transition: 0.15s;
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background: $main400;
      z-index: -1;
    }
    &:hover {
      color: $white;
      &::before {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .auth-actions {
    a {
      font-size: 14px;
      line-height: 17px;
    }
  }
}
