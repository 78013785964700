@import 'src/variables';

.box {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-indicator {
  width: 50px;
  height: 50px;
  position: relative;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-width: 3px;
    border-style: solid;
    box-sizing: border-box;
    border-radius: 50%;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  &::before {
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    border-color: $red400 transparent $red400 transparent;
    animation-name: outerRing;
    animation-direction: reverse;
    animation-duration: 0.8s;
  }
  &::after {
    width: 100%;
    height: 100%;
    border-color: $main400 transparent $main400 transparent;
    animation-name: innerRing;
  }
}

@keyframes outerRing {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes innerRing {
  0% {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(450deg);
  }
}
