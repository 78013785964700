@import 'src/variables';

.container {
  display: flex;
  height: 100%;
  .content-wrapper {
    width: calc(100% / 3 * 2);
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .logo {
      position: absolute;
      top: 40px;
      left: 40px;
      font-weight: 700;
      font-size: 29px;
      line-height: 35px;
      color: $main400;
    }
    h1 {
      margin-top: 0;
      margin-bottom: 48px;
      span {
        color: $main400;
      }
    }
    .content {
      max-width: 600px;
      width: 100%;
      form > *:not(:first-child) {
        margin-top: 40px;
      }
    }
  }
  .img {
    width: calc(100% / 3);
    background-size: cover;
    background-position: center;
  }
}

@media screen and (max-width: 980px) {
  .container {
    .content-wrapper {
      width: 100%;
      .content {
        max-width: none;
        padding: 40px;
      }
    }
  }
  .img {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .container {
    h1 {
      font-size: 24px;
      line-height: 36px;
    }
  }
}

@media screen and (max-width: 380px) {
  .container {
    .content-wrapper {
      .content {
        padding: 16px;
      }
    }
  }
}
