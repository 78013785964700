@import 'src/variables';

.search {
  position: relative;
  width: 600px;
  .icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $main400;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    svg {
      transition: 0.15s;
    }
    &:hover {
      svg {
        transform: scale(1.15);
      }
    }
  }
  .input {
    border: none;
    width: 100%;
  }
}

@media screen and (max-width: 1440px) {
  .search {
    width: 380px;
  }
}
@media screen and (max-width: 992px) {
  .search {
    width: 230px;
  }
}

@media screen and (max-width: 576px) {
  .search {
    width: 150px;
    display: flex;
    .icon {
      width: 18px;
      height: 18px;
      right: auto;
      left: 13px;
      background: none;
      svg {
        * {
          fill: $main400;
        }
      }
    }
    input {
      font-size: 14px;
      padding-left: 40px;
      padding-right: 13px;
      padding-top: 7.5px;
      padding-bottom: 7.5px;
    }
  }
}
