@import 'src/variables';

.tabs {
  display: flex;
  margin-bottom: 20px;
  .tab {
    font-size: 14px;
    line-height: 17px;
    color: $gray400;
    padding: 10px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: 0.15s;
    user-select: none;
    &[data-current='true'] {
      border-color: $main400;
      color: $main400;
      font-weight: 600;
    }
    & + .tab {
      margin-left: 15px;
    }
    &:hover {
      color: $main400;
    }
  }
}
