@import 'src/variables';

.button {
  padding: 12px 14px;
  background: $main400;
  border-radius: $borderRadius;
  color: $white;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  outline: none;
  border: 2px solid $main400;
  transition: 0.15s;
  cursor: pointer;
  width: 100%;
  svg * {
    transition: 0.15s;
  }
  &:hover {
    background: none;
    color: $main400;
    svg * {
      fill: $main400;
    }
  }
  &.small,
  &.transparent-small {
    padding: 7px 15px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    width: auto;
    border-width: 1px;
  }
  &.transparent-small {
    background: none;
    border-color: $gray400;
    color: $gray400;
    &:hover {
      border-color: $main400;
      color: $main400;
    }
  }
  &.alt {
    background-color: $secondary400;
    border-color: $secondary400;
    padding: 10px 11px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    width: auto;
    &:hover {
      background: none;
      color: $secondary400;
      svg * {
        fill: $secondary400;
      }
    }
  }
  &.red {
    background-color: $red400;
    border-color: $red400;
    &:hover {
      background: none;
      color: $red400;
      border-color: $red400;
      svg * {
        fill: $red400;
      }
    }
  }
  &.amber {
    background-color: $amber400;
    border-color: $amber400;
    &:hover {
      background: none;
      color: $amber400;
      border-color: $amber400;
      svg * {
        fill: $amber400;
      }
    }
  }
}
