@import 'src/variables';

.input {
  border: $border;
  border-radius: $borderRadius;
  outline: none;
  @include regularText();
  padding: 14px 20px;
  font-family: $fontRegular;
  color: $gray800 !important;
  transition: border-color 0.15s, color 0.15s;
  width: 100%;
  color: inherit;
  &.valid {
    border-color: $main400;
  }
  &.invalid {
    border-color: $red400;
  }
  &::placeholder {
    color: $gray300;
  }
}

@media screen and (max-width: 576px) {
  .input {
    font-size: 14px;
    line-height: 17px;
  }
}
