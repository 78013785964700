@import 'src/variables';

.date-select-input {
  height: 40px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  background: $white;
  border: 1px solid $gray200;
  border-radius: $borderRadius;
  transition: 0.15s;
  cursor: pointer;
  width: 100%;
  &:hover {
    filter: drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1))
      drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.05));
    border-color: $main400;
  }
  input {
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: -0.18px;
    color: #52525b;
    padding: 0;
    margin: 0;
    width: 100%;
    cursor: pointer;
  }
  .icon {
    width: 24px;
    position: relative;
    right: -8px;
  }
  .icon-clear {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    min-width: 24px;
    transition: 0.15s;
    position: relative;
    right: -8px;
    cursor: pointer;
    svg {
      transition: 0.15s;
    }
    &:hover {
      background: $main400;
      svg {
        fill: $white;
      }
    }
  }
}
