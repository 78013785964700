$white: #ffffff;

$main100: #34a3a733 !default;
$main200: #34a3a799 !default;
$main400: #34a3a7 !default;

$grayTransparent: #c4c4c44a !default;
$gray50: #f5f5f5 !default;
$gray100: #f3f4f6 !default;
$gray125: #eceef1 !default;
$gray200: #d0d0d0 !default;
$gray300: #828282cc !default;
$gray400: #828282 !default;
$gray500: #555555 !default;
$gray800: #000000 !default;

$secondary400: #585e91 !default;

$red100: #ff7d7d19 !default;
$red200: #ff7d7d4a !default;
$red400: #e80d1d !default;

$green200: #56e6824a !default;
$green400: #06af4f !default;

$amber200: #fee2524a !default;
$amber400: #f79f1a !default;

$borderRadius: 5px !default;
$border: 1px solid $gray200;

$fontSizeRegular: 16px;
$lineHeightRegular: 19px;

$fontRegular: 'Inter', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

@mixin regularText {
  font-size: $fontSizeRegular;
  line-height: $lineHeightRegular;
}
