@import 'src/variables';

.container {
  display: flex;
  min-height: 100%;
  max-height: 100%;
  background: $gray100;
  .title-container {
    display: flex;
    align-items: center;
    .action {
      margin-left: 24px;
      flex: 1;
    }
  }
  .nav-panel-toggle {
    display: none;
  }
  .nav-panel {
    background: $white;
    padding-top: 34px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 230px;
    .logo {
      font-weight: 700;
      font-size: 29px;
      line-height: 35px;
      color: #34a3a7;
      text-transform: uppercase;
      margin: 0 24px;
      margin-bottom: 46px;
    }
    .nav {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          a {
            display: block;
            padding: 0 24px;
            border-radius: $borderRadius;
            span {
              display: flex;
              padding: 16px;
              align-items: center;
              transition: 0.1s;
              svg {
                margin-right: 16px;
                * {
                  fill: $gray400;
                  transition: 0.1s;
                }
              }
            }
            &[data-current='true'],
            &:hover {
              span {
                background: $gray50;
                color: $main400;
                svg * {
                  fill: $main400;
                }
              }
            }
            &[data-current='true'] {
              position: relative;
              &::before {
                position: absolute;
                content: '';
                height: 100%;
                width: 3px;
                left: 0;
                top: 0;
                background: $main400;
                border-radius: 0px 3px 3px 0px;
              }
            }
          }
        }
      }
    }
    .logout {
      margin: 24px;
      margin-bottom: 18px;
      border-top: 1px solid $gray125;
      padding-top: 18px;
      span {
        color: $main400;
        display: flex;
        align-items: center;
        border-radius: $borderRadius;
        transition: 0.1s;
        padding: 16px;
        cursor: pointer;
        svg {
          margin-right: 16px;
        }
        &:hover {
          background: $gray50;
        }
      }
    }
  }
  .content {
    padding: 0 100px;
    padding-bottom: 37px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .header {
      padding: 27px 0;
      border-bottom: 1px solid $gray125;
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      .actions {
        display: flex;
        align-items: center;
        .notifications {
          height: 42px;
          width: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          background: $white;
          margin-right: 40px;
          transition: 0.1s;
          cursor: pointer;
          user-select: none;
          svg {
            width: 16px;
          }
          svg * {
            transition: 0.1s;
          }
          &:hover {
            background: $main400;
            svg * {
              fill: $white;
            }
          }
        }
        .profile {
          display: flex;
          align-items: center;
          .info {
            display: flex;
            flex-direction: column;
            text-align: end;
            .name {
              font-weight: 500;
            }
            .email {
              font-size: 13px;
            }
          }
          .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-left: 10px;
            background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 0.6),
                rgba(255, 255, 255, 0.6)
              ),
              #696cff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            img {
              width: 100%;
            }
            .status {
              position: absolute;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #71dd37;
              border: 2px solid $white;
              bottom: 0;
              right: 0;
              box-sizing: content-box;
            }
          }
          .actions {
            margin-left: 16px;
          }
        }
      }
    }
    .main {
      background: $white;
      border-radius: $borderRadius;
      padding: 30px 40px 10px 40px;
      flex: 1;
      overflow: auto;
    }
  }
}

@media screen and (max-width: 1600px) {
  .container {
    .content {
      padding: 0 50px;
      padding-bottom: 15px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .container {
    .content {
      padding: 0 30px;
      padding-bottom: 15px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .container {
    .nav-panel {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      height: 100vh;
      width: 300px;
      transition: 0.15s;
      &[data-open='false'] {
        left: -300px;
      }
      &[data-open='true'] {
        left: 0;
      }
    }
    .nav-panel-toggle {
      display: block;
      position: fixed;
      bottom: 44px;
      right: 44px;
      button {
        border-radius: 50%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .container {
    .content {
      .header {
        .actions {
          .profile {
            .info {
              .name {
                font-size: 14px;
                line-height: 15px;
              }
              .email {
                font-size: 11px;
                line-height: 11px;
              }
            }
            .actions {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .container {
    .content {
      padding: 0 22px;
      .header {
        .actions {
          .profile {
            .avatar {
              width: 25px;
              height: 25px;
            }
            .info {
              .name {
                font-size: 12px;
                line-height: 15px;
              }
              .email {
                font-size: 9px;
                line-height: 11px;
              }
            }
          }
          .notifications {
            margin-right: 20px;
            margin-left: 8px;
            width: 32px;
            height: 32px;
          }
        }
      }
    }
    .title-container {
      .action {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 56px;
        justify-content: flex-start;
        background-color: $white;
        box-shadow: 0px -2px 14px rgba(0, 0, 0, 0.11);
        margin-left: 0;
        padding: 10px 20px;
      }
    }

    .nav-panel-toggle {
      bottom: 30px;
      right: 30px;
    }
  }
}
@media screen and (max-width: 400px) {
  .container {
    .content {
      padding: 0 8px;
      .notifications {
        margin-right: 8px !important;
      }
    }
  }
}
