@import 'fonts';
@import 'src/variables';

html {
  font-size: $fontSizeRegular;
  font-family: $fontRegular;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: 100%;
  color: $gray400;
  & * {
    font-family: $fontRegular;
    box-sizing: border-box;
  }
}

#root {
  height: 100%;
}

h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  color: $gray800;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.1s;
  &:hover {
    color: $main400;
  }
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 28px;
    line-height: 34px;
  }
}
