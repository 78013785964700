.filters {
  display: flex;
  margin-bottom: 24px;
  & > * + * {
    margin-left: 24px;
  }
  .labeled-field {
    margin-top: 0;
  }
}
