@import 'src/variables';

.container .container-content .main {
  .count {
    align-items: center;
    .wrapper {
      align-items: center;
    }
    .content {
      font-size: 20px;
    }
  }
}
